import { WebStorageStateStore } from 'oidc-client';
import { ApplicationSettingInterface } from '../models/application-setting.model';

export const environment: ApplicationSettingInterface = {
  name: 'Beta',
  clientApplicationId: 2,
  apiUrl: 'https://onix-maker-api-beta.azurewebsites.net',
  identityApiUrl: 'https://login-beta.onixwork.com',
  myAccountUrl: 'https://myaccount-beta.onixwork.com/',
  enableBanner: false,
  applicationInsightKey: 'e85b9634-431e-4f6c-8415-ca3bbca67c0b',
  applicationInsightBufferKey: 'applicationInsightBuffer',
  oidcSetting: {
    authority: 'https://login-beta.onixwork.com',
    client_id: 'F9U5hu4vrDR8gyhF54diJ2tsJZ8p5G0Tbe',
    redirect_uri: 'https://maker-beta.onixwork.com/auth-callback',
    post_logout_redirect_uri: 'https://maker-beta.onixwork.com/login',
    response_type: 'code',
    scope: 'openid email onixmaker offline_access',
    filterProtocolClaims: false,
    loadUserInfo: false,
    automaticSilentRenew: false,
    //checkSessionInterval: 2000,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
    monitorSession: true,
    includeIdTokenInSilentRenew: false,
    revokeAccessTokenOnSignout: true,
  },
};
